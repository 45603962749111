import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ServicesService } from '../../services/services.service';
import { environment } from 'src/environments/environment';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  nameLength = 20;
  services:any;
  status = 'all';
  base_url = environment.baseUrl;
  constructor(private spinner: NgxSpinnerService,private service:CategoryService) { }
toggleShow(item:any){
  const form = {section_id:item.id , is_visible:item.is_visible==1?0:1}
  this.service.update(form).subscribe((response:any)=>{
    this.spinner.hide()
    Swal.fire(
      `نجاح`,
      `تم تعديل القطاع`,
      `success`
    )
    item.is_visible =!item.is_visible
  })
}
  delete(service_id){
    Swal.fire({
      title: `يرجي الانتاه`,
      text: `هل انت متأكد من حذف القطاع !`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا'

    }).then((result) => {
      if (result.value) {
        return this.service.delete(service_id).pipe().subscribe((response) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'تم الحذف بنجاح',
            showConfirmButton: false,
            timer: 1500
          })
          this.getServices(this.status)
        })}

      }
    )
  }
  getServices(status){
    this.spinner.show()

    this.service.list().pipe(map(response=>response['data'])).subscribe(services=>{
      console.log(services , 'services');

      this.services = services
      localStorage.setItem('sections', JSON.stringify(this.services))
      this.spinner.hide()

    })
  }
  ngOnInit(): void {

    this.getServices(this.status)
  }

  moveIndex(prev:any , next:any ,  index_next:any, index_prev:any ,){
let form:any={
  from_section_id:prev,
to_section_id:next,
from_index:index_next,
to_index:index_prev,
}
this.service.updateIndex(form).subscribe(response=>{
  console.log(response)
  this.getServices(this.status)
})
  }
}
