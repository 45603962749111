import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http:HttpClient) { }
  list(){
    return this.http.get(`${environment.endpoint}/sections`)
  }
  create(service:any):any {
    const formData:FormData = new FormData();
    for (const [key, value] of Object.entries(service)) {
      if(key==`image`){
        formData.append(`image`,service.image)
      }
     else{
        formData.append(key,`${value}`)
      }
    }
    return this.http.post(`${environment.endpoint}/section/create`,formData)
  }
  update(service:any){
    const formData:FormData = new FormData();
    for (const [key, value] of Object.entries(service)) {
      if(value!=null)
        if(key==`image`){
          formData.append(`image`,service.image)
        }
       else{
          formData.append(key,`${value}`)
        }
    }
    return this.http.post(`${environment.endpoint}/section/update`,formData)
  }
  delete(service_id){
    return this.http.delete(`${environment.endpoint}/section/delete?section_id=${service_id}`)
  }
  updateIndex(service:any){
    return this.http.post(`${environment.endpoint}/section/index/update`,service)
  }
}
