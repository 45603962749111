import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UpdateComponent } from './update/update.component';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';


const routes: Routes = [
  {path:'',component:CreateComponent, data: { title: 'إضافة قطاع جديد' }},
  {path:'create',component:CreateComponent, data: { title: 'إضافة قطاع جديد' }},
  {path:'list',component:ListComponent, data: { title: 'عرض القطاعات' }},
  {path:'update/:category_id',component:UpdateComponent, data: { title: 'تعديل القطاع ' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CategoryRoutingModule { }
