import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaqRoutingModule } from './faq-routing.module';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';

import {MatFormFieldModule} from '@angular/material/form-field';
@NgModule({
  declarations: [CreateComponent, ListComponent, EditComponent],
  imports: [

    CommonModule,
    FaqRoutingModule,
    HttpClientModule,
    QuillModule.forRoot(),
    FormsModule,
    MatFormFieldModule,

  ]
})
export class FaqModule { }
