import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public timeNow: Date = new Date();
  public welcomePhrase;
  statistics = {
    services : 0,
    technologies : 0,
    projects : 0,
    employees : 0,
    visitors : 0,
  }
  currentUser: any;
  constructor(private http:HttpClient,private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  info(){
    this.http.get(`${environment.endpoint}/dashboard`).subscribe((res:any)=>{
      this.statistics = res.data
    })
  }
  ngOnInit(): void {
    this.info()
    this.getDate()
  }
  getDate(){
    setInterval(() => { this.timeNow = new Date() }, 1);
    var myDate = new Date();
    var hrs = myDate.getHours();
    if (hrs < 12)
      this.welcomePhrase = 'صباح الخير';
    else
      this.welcomePhrase = 'مساء الخير';
  }
}
