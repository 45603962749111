import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { DefinitionService } from '../definition.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  defines: any=[];
  nameLength = 12;


  base_url = environment.baseUrl;
  constructor(private define:DefinitionService , private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.getServices()
  }
  getServices(){
    this.spinner.show()

    this.define.list().pipe(map(response=>response['data'])).subscribe(services=>{
      this.defines = services
      localStorage.setItem('defines', JSON.stringify(this.defines))
      this.spinner.hide()

    })
  }
  deActivate(service,status){
    this.spinner.show()
    console.log(service,status)
    var form = {
      definition_id:service.id,is_visible:status
    }
    this.define.update(form).subscribe(response=>{
      console.log(response)
      console.log(response)
      this.getServices()
    })
  }
  moveIndex( prev :any , next:any ,  index_next:any, index_prev:any ,){
    let form:any={
      from_definition_id:prev,
      to_definition_id:next,
    from_index:index_next,
    to_index:index_prev,
    }
    this.define.updateIndex(form).subscribe(response=>{
      console.log(response)
      this.getServices()
    })
      }
      delete(service_id){
        Swal.fire({
          title: `يرجي الانتاه`,
          text: `هل انت متأكد من حذف المصطلح !`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'نعم',
          cancelButtonText: 'لا'

        }).then((result) => {
          if (result.value) {
            return this.define.delete(service_id).pipe().subscribe((response) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'تم الحذف بنجاح',
                showConfirmButton: false,
                timer: 1500
              })
              this.getServices()
            })}

          }
        )
      }
}
