import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ServicesService } from '../../services/services.service';
import { Router } from '@angular/router';
import { JosService } from '../jos.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public form:FormGroup;
  public submitted = false;
  JobsList: any;
  constructor(private router: Router,private formbuilder:FormBuilder,private service:JosService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.formbuilder.group({

      title:['',Validators.required],
      type:['',Validators.required],
      category_id:['',Validators.required],
      link:['',Validators.required],
      location:['',Validators.required],

    })
    this.showAll()
  }
showAll(){
  this.spinner.show()
  this.service.list().subscribe((data:any) => {
    console.log(data);

    this.JobsList = data.data;

    this.spinner.hide()
  });
}

  get f() { return this.form.controls }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) { return }
    this.spinner.show()
    const form = {...this.form.value}
    console.log(form);

    this.service.AddJobs(form).subscribe((response:any)=>{
      this.spinner.hide()
      Swal.fire(
        `نجاح`,
        `تم إضافة الوظيفة`,
        `success`
      )
      this.router.navigate(["/app/jobs/list"])
    })
  }
}
