import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddComponent } from './add/add.component';

import { UpdateComponent } from './update/update.component';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
  {path:'',component:AddComponent, data: { title: 'إضافة مصطلح جديد ' }},
  {path:'create',component:AddComponent, data: { title: 'إضافة مصطلح جديد ' }},
  {path:'list',component:HomeComponent, data: { title: 'عرض المصطلحات' }},
  {path:'update/:define_id',component:UpdateComponent, data: { title: ' تعديل المصطلح' }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DefinitionRoutingModule { }
