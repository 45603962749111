import { CategoryService } from './../category.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  public form:FormGroup;
  public submitted = false;
  public image:File = null;
  public base_64_image:any;
  constructor(private router: Router,private formbuilder:FormBuilder,private service:CategoryService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.formbuilder.group({

      title:['',Validators.required],
      description:['',Validators.required],
      image:[''],


    })
  }

  detectImage(event) {
    const file = event.target.files[0];
    this.image = file
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.base_64_image = reader.result;
    };
  }
  get f() { return this.form.controls }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid ||this.image==null) { return }
    this.spinner.show()
    const form = {...this.form.value,image:this.image}
    console.log( form);

    this.service.create(form).subscribe((response:any)=>{
      this.spinner.hide()
      Swal.fire(
        `نجاح`,
        `تم إضافة الخدمة`,
        `success`
      )
      this.router.navigate(["/app/category/list"])
    })
  }
}
