import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public faq_id = this.route.snapshot.paramMap.get('faq_id');
  item:any=''
  question:any=''
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6] }],
        ['link', 'image', 'video'],
        [{ font: [] }],
        [{ align: [] }],

        ['clean'], // remove formatting button
      ],
    },
  };

htmlText: any='';
submitted: boolean=false;
  constructor(private router:Router,private route:ActivatedRoute , private spinner: NgxSpinnerService,private faq:FaqService ,) { }

  ngOnInit(): void {
    const list= JSON.parse(localStorage.getItem('faqList'));
    console.log(list);
this.item=list.find((item :any )=> item.id==this.faq_id);
console.log(this.item);
this.question=this.item.question;
this.htmlText=this.item.answer;
  }
update(){
  this.submitted=true;
  if(this.question=='' || (this.htmlText=='' || this.htmlText==null)){
    return;
  }
  let form={
   question: this.question,
   answer:this.htmlText,
   faq_id:this.faq_id
  }
  console.log(form);
  this.spinner.show()
  this.faq.updateFaq(form).subscribe((response:any)=>{
    console.log(response)
    this.spinner.hide()
    Swal.fire(
      `نجاح`,
      `تم تعديل السؤال`,
      `success`
    )
    this.router.navigate(["/app/faq/list"])
    // window.location.reload()
  })
}
}
