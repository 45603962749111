import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DefinitionService } from '../definition.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  public service_id = this.route.snapshot.paramMap.get('define_id');
  change: boolean=false;
;  base_url = environment.baseUrl;
  selectedService = null;
  public submitted = false;
  public image:File = null;
  public base_64_image:any;
  public form:FormGroup;
  constructor(private route: ActivatedRoute,private router: Router,private formbuilder:FormBuilder,private service:DefinitionService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      title_EN:['',Validators.required],
      title_AR:['',Validators.required],

      description_AR:['',Validators.required],

      image:['',Validators.nullValidator],

    })
    this.getService()
  }
  getService(){
    const list= JSON.parse(localStorage.getItem('defines'));
    console.log(list);
this.selectedService=list.find((item :any )=> item.id==this.service_id);

      this.base_64_image =  this.base_url+'/'+this.selectedService.image
      console.log(this.base_64_image);
      this.displayInData(this.form.controls,this.selectedService)





  }

  displayInData(formControls,keys){
    const requiredControls = Object.entries(formControls).filter((control:any)=>control[1].errors != null).map(control=>control[0]);
    for (const [key, value] of Object.entries(keys)) {
      (requiredControls.includes(key) ? formControls[key].setValue(value) : false);


    }

  }
  get f() { return this.form.controls }
  detectImage(event) {
    console.log(event)
    const file = event.target.files[0];
    this.image = file
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.change=true
        this.base_64_image = reader.result;
    };
  }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) { return }
    this.spinner.show()
    const form = {
      ...this.form.value,
      image: this.change?this.image:null,
      definition_id:this.service_id

    }
    this.service.update(form).subscribe((response:any)=>{
      this.spinner.hide()
      Swal.fire(
        `نجاح`,
        `تم تعديل المصطلح `,
        `success`
      )
      this.router.navigate(["/app/definition/list"])
    })
  }
}
