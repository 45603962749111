import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoryService } from '../../category/category.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JosService } from '../jos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public faq_id = this.route.snapshot.paramMap.get('id');
  item: any;
  public form:FormGroup;
  public submitted = false;
  JobsList: any;
  constructor(private route:ActivatedRoute ,private router: Router,private formbuilder:FormBuilder,private service:JosService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    const list= JSON.parse(localStorage.getItem('JobsCatList'));
    console.log(list);
this.item=list.find((item :any )=> item.id==this.faq_id);
console.log(this.item);
this.form = this.formbuilder.group({

  title:[this.item.title,Validators.required],
  type:[this.item.type,Validators.required],
  category_id:[this.item.category_id,Validators.required],
  link:[this.item.link,Validators.required],
  location:[this.item.location,Validators.required],

})
this.showAll()
}
showAll(){
this.spinner.show()
this.service.list().subscribe((data:any) => {
  console.log(data);

  this.JobsList = data.data;

  this.spinner.hide()
});
}

get f() { return this.form.controls }
onSubmit() {
  this.submitted = true;
  if (this.form.invalid) { return }
  this.spinner.show()
  const form = {...this.form.value , job_id:this.faq_id}
  console.log(form);

  this.service.EditJobs(form).subscribe((response:any)=>{
    this.spinner.hide()
    Swal.fire(
      `نجاح`,
      `تم تعديل الوظيفة`,
      `success`
    )
    this.router.navigate(["/app/jobs/list"])
  })
}
}
