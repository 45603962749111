import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DefinitionService } from '../definition.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  public submitted = false;
  public image:File = null;
  public base_64_image:any;
  public form:FormGroup;
  constructor(private router: Router,private formbuilder:FormBuilder,private service:DefinitionService,private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.form = this.formbuilder.group({
      title_EN:['',Validators.required],
      title_AR:['',Validators.required],

      description_AR:['',Validators.required],

      image:['',Validators.nullValidator],

    })
  }
  get f() { return this.form.controls }
  detectImage(event) {
    console.log(event)
    const file = event.target.files[0];
    this.image = file
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

        this.base_64_image = reader.result;
    };
  }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) { return }
    this.spinner.show()
    const form = {
      title_EN:this.form.value.title_EN,
      title_AR:this.form.value.title_AR,
      description_EN:'-',
      description_AR:this.form.value.description_AR,
      image: this.image,


    }
    this.service.add(form).subscribe((response:any)=>{
      this.spinner.hide()
      Swal.fire(
        `نجاح`,
        `تم أضافة المصطلح `,
        `success`
      )
      this.router.navigate(["/app/definition/list"])
    })
  }
}
