import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http:HttpClient) { }
  list(){
    return this.http.get(`${environment.endpoint}/faqs`)
  }
  updateIndex(service:any){
    return this.http.post(`${environment.endpoint}/faq/index/update`,service)
  }
  updateFaq(service:any){
    return this.http.post(`${environment.endpoint}/faq/update`,service)
  }
 addFaq(service:any){
    return this.http.post(`${environment.endpoint}/faq/create`,service)
  }
 delFaq(service:any){
    return this.http.delete(`${environment.endpoint}/faq/delete?faq_id=${service}`)
  }
}
