import { Component, OnInit } from '@angular/core';
import { FaqService } from '../faq.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  faqList:any=[]
  nameLength=12
  constructor(private faq:FaqService , private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.showAll()
  }
showAll(){
  this.spinner.show()
  this.faq.list().subscribe((data:any) => {
    console.log(data);

    this.faqList = data.data;
    localStorage.setItem('faqList', JSON.stringify(this.faqList))
    this.spinner.hide()
  });
}
delete(service_id){
  Swal.fire({
    title: `يرجي الانتاه`,
    text: `هل انت متأكد من حذف السؤال !`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'نعم',
    cancelButtonText: 'لا'

  })
  .then((result) => {
    if (result.value) {
      return this.faq.delFaq(service_id).pipe().subscribe((response) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم الحذف بنجاح',
          showConfirmButton: false,
          timer: 1500
        })
        this.showAll()
      })}
    })
}
moveIndex(next :any , prev:any ,   index_next:any, index_prev:any ,){
  let form:any={
    from_faq_id:next,
  to_faq_id:prev,
  from_index:index_next,
  to_index:index_prev,
  }
  this.faq.updateIndex(form).subscribe(response=>{
    console.log(response)
    this.showAll()
  })
    }
}
