import { Component, OnInit } from '@angular/core';
import { JosService } from '../jos.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  JobsList:any=[]
question: any='';
submitted: boolean=false;
edit:boolean=false;
  id: any;
  showJob:boolean=false;
  JobsCatList: any;
  categ: any;
  constructor(private faq:JosService , private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.showAll()
  }
showAll(){
  this.spinner.show()
  this.faq.list().subscribe((data:any) => {
    console.log(data);

    this.JobsList = data.data;

    this.spinner.hide()
  });
}
add(){
  this.submitted=true;
  this.faq.addCat({name:this.question}).subscribe((data:any) => {
    console.log(data);

  this.showAll()
this.question=''
  });
}
editCats(){
  this.submitted=true;
  this.faq.editCat({name:this.question, category_id:this.id}).subscribe((data:any) => {
    console.log(data);

  this.showAll()
this.question=''
this.submitted=false;
  });
}
Edit(item){
  this.edit=true;
  this.id=item.id;
  this.question=item.name
}
delete(service_id){
  Swal.fire({
    title: `يرجي الانتاه`,
    text: `هل انت متأكد من حذف التصنيف !`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'نعم',
    cancelButtonText: 'لا'

  })
  .then((result) => {
    if (result.value) {
      return this.faq.deleteCat(service_id).pipe().subscribe((response) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم الحذف بنجاح',
          showConfirmButton: false,
          timer: 1500
        })
        this.showAll()
      })}
    })
}
showJobs(item){
  this.spinner.show()
  this.showJob=true;

  this.faq.getJobs(item.id).subscribe((data:any) => {
    console.log(data);
    this.categ=item
    this.JobsCatList = data.data;
    localStorage.setItem('JobsCatList', JSON.stringify(this.JobsCatList))
    this.spinner.hide()
  });
}
delJobs(service_id){
  Swal.fire({
    title: `يرجي الانتاه`,
    text: `هل انت متأكد من حذف الوظيفة !`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'نعم',
    cancelButtonText: 'لا'

  })
  .then((result) => {
    if (result.value) {
      return this.faq.delJobs(service_id.id).pipe().subscribe((response) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم الحذف بنجاح',
          showConfirmButton: false,
          timer: 1500
        })
        this.showJobs(this.categ)
      })}
    })
}
}
